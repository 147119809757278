@font-face {
  font-family: "poppins";
  src: url("assets/fonts/Poppins-Regular.woff") format('woff');
  font-weight:400
}

@font-face {
  font-family: "poppins";
  src: url("assets/fonts/Poppins-Bold.woff") format('woff');
  font-weight: bold;
}

@font-face {
  font-family: "poppins";
  src: url("assets/fonts/Poppins-Italic.woff") format('woff');
  font-style: italic;
}


body {
  font-family: poppins;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  color:black;
  background-image: url('assets/images/breakchain/homepage-bg-white.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.header {
  background-color: #001DA3;
  display: flex;
  justify-content:space-between;
  align-items:center;
  top: 0px; 
  left: 0px;
  min-height: 56px;
  width:100%;
}
.priceHolder {
  padding: 0 10px;
}
.priceticker {
  color:white;
  font-size:1em;
  margin-RIGHT: 10px;
}

.enterbtn {
  text-align: right;
}

.price {
  color:#32CBFF;
  font-size:1.2em;
  
}

.apyHolder {
  padding: 0 10px;
}

.apyticker {
  color:white;
  font-size:1em;

}
.apy {
  color:#32CBFF;
  font-size:1.2em;
  // margin-left: 10px;
}
.enterbutton {
  padding: 0 10px;
  text-align:center;
}


// #########################

.main {
  margin-top:80px;
  background-color: white;
  overflow:hidden;
  max-width:100%;
}
.maintext {
  display:inline-block;
}
.slideBlock {
  display: inline-block;
}
.slides1 {
  animation:30s linear infinite running slider;
  display: inline-block;
  white-space: nowrap;
  overflow:hidden;
}
@keyframes slider {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-25%, 0, 0);
  }
}
@keyframes slidein1 {
  from {
    margin-left: 0%;
  }

  to {
    margin-left: -100%;
  }
}
@keyframes slidein2 {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 0%;
  }
}

.mainblack {
  background-color: black;
  color: white;
  min-height: 385px;
  margin-top: 80px;
  display: block;
  text-align: center;
}

.mainblackHolder {
  display: flex;
  align-items: center;
  justify-content:space-around;
  flex-wrap: wrap;
  min-height: 385px;
}
.mainblackLogo {
  display: block;
  flex:1 1 auto;
  padding:60px 10px;
  // min-height: 385px;
}
.blacktext {
  font-family: futura, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2em;
  width: 300px;
  display: block;
  flex:1 1 auto;
  padding:10px 10px;
  // min-height: 385px;
  // height: 385px;
  a {
    color: #32CBFF;
  }
}

// #########################
embed {
  width:600px;
  height:337px;
}
@media screen and (max-width: 600px) {
  embed {
    width:400px;
    height:225px;
  }
}
.videos {
  margin:80px 0;
  display:flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  max-width:100%;
}
.embedHolder {
  background-color: #32CBFF;
  padding:10px;
  margin-top:10px;
  color: white;
}
.embedText {
  text-align: center;
  color: white;
  font-size: large;
}

// #########################

.partners {
  padding:10px 0;
  display: block;
  left:0px;
  width: 100%;
  background-color: white;
  color:black;
}
.partnerstitle {
  text-align:center;
  margin-top:0;
  font-size:1.5em;
  font-family:'Arial Black';
}
.partnerholder {
  display: flex;
  flex-wrap: wrap;
}
.partner {
  flex:1 1 auto;
}

// #########################

.footer {
  background-color: #001DA3;
  display: block;
  width:100%;
  left: 0px;
  min-height:150px;
}
.footerHolder {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height:150px;
  justify-content: space-between;
}
.socialSection {
  padding:10px 10px;
  display: flex;
  flex:1 1 auto;
  justify-content: space-evenly;
}
.social-row {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 225px;
  text-decoration: none;
  
  a {
    &:hover {
      text-decoration: none;
    }
    text-decoration: none;
    div {
      color:white;
      font-size:1.5em;
      &:hover {
        color:#32CBFF;
      }
    }
  }
}
.social-icon {
  text-decoration: none;
}
.contactSection {
  display: flex;
  flex:1 1 auto;
  padding: 10px 10px;
  flex-direction: column;
  align-items: center;
}
.contactText {
  color: white;
  font-size:1.2em;
}
.contactEmail {
  color: white;
  font-size:.8em;
  a {
    color:white !important;
    &:hover {
      color:#32CBFF !important;
    }
  }
}
.emailSection {
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  flex:1 1 auto;
  justify-content: space-evenly;
  flex-wrap: nowrap;
}
.emailHolder {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.textfieldHeader {
  color:#32CBFF;
  font-size:.9em;
}
.textfieldSection {
  display: block;
  background-color: white;
  height: 60px;
  border-radius: 60px;
  padding: 0 30px;
  margin-right:10px;
}
.emailButton {
  display: block;
  height: 60px;
  border-radius: 60px;
  margin-left: 10px;

}



